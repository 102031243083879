import React from 'react';
import { View, StyleSheet, Text, SafeAreaView } from 'react-native';

import { Colors, Typography } from '../constants';
import { connect } from 'react-redux';
import { updateLogin, login } from '../ducks/UserSessionDuck';

class LandingScreen extends React.Component {
    _handleLogin = () => {
        this.props.login({
            email: this.props.email,
            password: this.props.password,
        });
    };

    render() {
        return (
            <SafeAreaView style={styles.containerSafeArea}>
                <View style={styles.container}>
                    <Text
                        style={{
                            ...Typography.h1,
                        }}
                    >
                        Hello World!
                    </Text>
                </View>
            </SafeAreaView>
        );
    }
}

const styles = StyleSheet.create({
    containerSafeArea: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
        alignItems: 'center',
        paddingTop: 30,
    },
});

const mapStateToProps = ({ userSession, firebase }) => {
    const { email, password, error, loading } = userSession;

    const { auth, profile } = firebase;

    return {
        email: email ? email : '',
        password: password ? password : '',
        error,
        loading,
        auth,
        profile,
    };
};

export default connect(mapStateToProps, {
    updateLogin,
    login,
})(LandingScreen);
