import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Text, TouchableOpacity, Platform, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import moment from 'moment';

import { Colors, Typography } from '../../constants';
import { db } from '../../config/Firebase';

class TaskItem extends Component {
    state = {
        expanded: false,
        isFirstOpened: true,
    };
    componentDidMount() {}

    render() {
        let task = this.props.task;

        let date = moment.unix(task.date.seconds).format('MMM. D');

        let isLoved = false;

        if (task.loves) {
            const index = task.loves.findIndex((love) => {
                return this.props.profile.uid === love.id;
            });

            if (index != -1) {
                isLoved = true;
            }
        }

        return (
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                }}
                // onPress={() => {
                //     if (this.props.isFirst) {
                //         this.setState({
                //             isFirstOpened: !this.state.isFirstOpened,
                //         });
                //     } else {
                //         this.setState({
                //             expanded: !this.state.expanded,
                //         });
                //     }
                // }}
                activeOpacity={0.5}
            >
                <View
                    style={{
                        flex: 1,
                        height: 50,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: Colors.blueSapphire,
                    }}
                >
                    <Text
                        style={{
                            ...Typography.subtitle1,
                            fontSize: 24,
                            color: Colors.white,
                        }}
                    >
                        {task.message ? task.message : task.description}
                    </Text>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ firebase }) => {
    const { auth, profile } = firebase;

    return {
        auth,
        profile,
    };
};

export default connect(mapStateToProps, {})(TaskItem);
