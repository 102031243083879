import React from 'react';
import { View, TextInput, StyleSheet, TouchableOpacity, Text, SafeAreaView } from 'react-native';
import { connect } from 'react-redux';

import { Colors, Typography } from '../constants';
import { updateLogin, signup } from '../ducks/UserSessionDuck';

class SignupScreen extends React.Component {
    state = {
        fullName: '',
        userName: '',
    };

    _handleSignUp = () => {
        this.props.signup({
            email: this.props.email,
            password: this.props.password,
            fullName: this.state.fullName,
            userName: this.state.userName,
        });
    };

    render() {
        return (
            <SafeAreaView style={styles.containerSafeArea}>
                <View style={styles.container}>
                    <TextInput
                        style={styles.inputBox}
                        value={this.state.fullName}
                        onChangeText={(fullName) => this.setState({ fullName })}
                        placeholder='Full Name'
                    />
                    <TextInput
                        style={styles.inputBox}
                        value={this.state.userName}
                        onChangeText={(userName) => this.setState({ userName })}
                        placeholder='Username'
                    />
                    <TextInput
                        style={styles.inputBox}
                        value={this.props.email}
                        onChangeText={(email) =>
                            this.props.updateLogin({
                                email,
                                password: this.props.password,
                            })
                        }
                        placeholder='Email'
                        autoCapitalize='none'
                    />
                    <TextInput
                        style={styles.inputBox}
                        value={this.props.password}
                        onChangeText={(password) =>
                            this.props.updateLogin({
                                email: this.props.email,
                                password,
                            })
                        }
                        placeholder='Password'
                        secureTextEntry={true}
                    />
                    <TouchableOpacity style={styles.button} onPress={this._handleSignUp}>
                        <Text style={styles.buttonText}>Signup</Text>
                    </TouchableOpacity>
                </View>
            </SafeAreaView>
        );
    }
}

const styles = StyleSheet.create({
    containerSafeArea: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
    container: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
        alignItems: 'center',
        paddingTop: 30,
    },
    inputBox: {
        width: '85%',
        margin: 10,
        padding: 15,
        borderColor: Colors.blueSapphire,
        borderBottomWidth: 1,
        textAlign: 'center',
        ...Typography.subtitle1,
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: Colors.rufous,
        borderColor: Colors.rufous,
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
    },
    buttonText: {
        ...Typography.button,
        color: '#fff',
    },
});

const mapStateToProps = ({ userSession }) => {
    const { email, password, error, loading } = userSession;

    return {
        email: email ? email : '',
        password: password ? password : '',
        error,
        loading,
    };
};

export default connect(mapStateToProps, {
    updateLogin,
    signup,
})(SignupScreen);
