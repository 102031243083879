import React from 'react';
import moment from 'moment';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Colors, Typography } from '../../constants';
// import { GlobalStyles } from '@constants';

const styles = StyleSheet.create({
    // ...GlobalStyles,
});

const WeekPicker = ({ activeWeek, setActiveWeek }) => {
    const toDate = activeWeek.clone().endOf('isoWeek');
    const weekRange = activeWeek.format('MMM DD - ') + toDate.format('MMM DD, YYYY');

    return (
        <View
            style={{
                backgroundColor: Colors.mediumChampagne,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingHorizontal: 40,
                paddingVertical: 10,
            }}
        >
            <TouchableOpacity
                onPress={() => {
                    setActiveWeek(activeWeek.subtract(7, 'days'));
                }}
            >
                <Text style={{ ...Typography.button }}>Back</Text>
            </TouchableOpacity>
            <View style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ ...Typography.h5, alignSelf: 'center' }}>{weekRange}</Text>
            </View>
            <TouchableOpacity
                onPress={() => {
                    setActiveWeek(activeWeek.add(7, 'days'));
                }}
            >
                <Text style={{ ...Typography.button }}>Next</Text>
            </TouchableOpacity>
        </View>
    );
};

export default WeekPicker;
