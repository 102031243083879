import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Text } from 'react-native';

import { Colors, Typography } from '../../constants';

class GoalItem extends Component {
    render() {
        const { goal } = this.props;

        return (
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                }}
                activeOpacity={0.5}
            >
                <View
                    style={{
                        flex: 1,
                        height: 50,
                        flexDirection: 'row',
                        // justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: goal.completionDate ? Colors.gamboge : goal.missDate ? Colors.rubyRed : Colors.blueSapphire,
                    }}
                >
                    <Text
                        style={{
                            ...Typography.subtitle1,
                            color: Colors.white,
                        }}
                    >
                        {goal.role}
                    </Text>
                    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: Colors.white,
                                flex: 1,
                            }}
                        >
                            {goal.description}
                        </Text>
                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ firebase }) => {
    const { auth, profile } = firebase;

    return {
        auth,
        profile,
    };
};

export default connect(mapStateToProps, {})(GoalItem);
